import React, { useRef } from "react";
import useRunAfterUpdate from "../hooks/useRunAfterUpdate";

import {
  isInvalidCurrencyInput,
  removeCurrencyInputFormatting,
  formatCurrencyInput,
} from "../utils/currency";

import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

interface Props {
  submissionValue: string;
  setSubmissionValue: React.Dispatch<React.SetStateAction<string>>;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

const SalesSubmissionForm: React.FC<Props> = (props) => {
  const salesInputRef = useRef<HTMLInputElement>(null);

  const runAfterUpdate = useRunAfterUpdate();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSubmissionValue = event.target.value;

    if (isInvalidCurrencyInput(newSubmissionValue)) {
      const cursor = event.target.selectionStart;
      const newCursor = !cursor
        ? props.submissionValue.length
        : cursor - newSubmissionValue.length + props.submissionValue.length;

      runAfterUpdate(() => {
        if (!salesInputRef.current) return;

        salesInputRef.current.selectionStart = newCursor;
        salesInputRef.current.selectionEnd = newCursor;
      });

      return;
    }

    props.setSubmissionValue(newSubmissionValue);
  };

  const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!props.submissionValue) return;

    const newSubmissionValue = removeCurrencyInputFormatting(
      props.submissionValue
    );

    props.setSubmissionValue(newSubmissionValue);

    runAfterUpdate(() => {
      if (!salesInputRef.current) return;

      salesInputRef.current.selectionStart = newSubmissionValue.length;
      salesInputRef.current.selectionEnd = newSubmissionValue.length;
    });
  };

  const handleOnBlur = () =>
    props.setSubmissionValue((prevSubmissionValue) =>
      formatCurrencyInput(prevSubmissionValue)
    );

  return (
    <Form onSubmit={props.handleSubmit}>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>Monthly Sales Figure (Ex GST)</Form.Label>
            <Form.Control
              ref={salesInputRef}
              type="string"
              placeholder="0.00"
              inputMode="decimal"
              value={props.submissionValue}
              onChange={handleChange}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
            />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col className="d-flex justify-content-end">
          <Button
            variant="primary"
            type="submit"
            disabled={!props.submissionValue}
          >
            Submit
          </Button>
        </Col>
      </Form.Row>
    </Form>
  );
};

export default SalesSubmissionForm;
