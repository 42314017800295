/**
 * @returns true if the input is not in a currency format (decimal number with a precision of 2), otherwise it returns false
 */
export const isInvalidCurrencyInput = (input: string) => {
  // input contains characters that aren't digits or decimals
  if (/[^0-9.]/g.test(input)) return true;
  // input contains multiple decimals
  if (getSubStringCount(input, ".") > 1) return true;
  // input contains 3 or more digits after the decimal
  if (/\.[0-9]{3,}/.test(input)) return true;
  // input contains a leading zeros and is not a zero or cent value
  if (/^0/.test(input) && input !== "0" && !/^0\./.test(input)) return true;

  return false;
};

/**
 * @returns the input, removing all characters other than numbers and full stops (decimals)
 */
export const removeCurrencyInputFormatting = (formattedInput: string) =>
  formattedInput.replace(/[^0-9.]/g, "");

/**
 * @returns the input formatted as a currency (AUD)
 */
export const formatCurrencyInput = (input: string) => {
  if (!input) return "";
  if (input === ".") return "$0.00";

  return new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",
  }).format(parseFloat(input));
};

/**
 * @returns the number of times the subset occurs in the string
 */
const getSubStringCount = (string: string, subString: string) =>
  string.replace(new RegExp(`[^${subString}]`, "g"), "").length;
