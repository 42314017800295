import React from "react";

import ContactInformation from "./ContactInformation";

interface Props {
  centreName: string;
  centrePhoneNumber: string | null;
  centreEmail: string | null;
  greeting: string;
  message: string;
}

const InfoPage: React.FC<Props> = (props) => (
  <React.Fragment>
    <h2>{props.greeting}</h2>
    <p>{props.message}</p>
    <ContactInformation
      centreName={props.centreName}
      centrePhoneNumber={props.centrePhoneNumber}
      centreEmail={props.centreEmail}
    />
  </React.Fragment>
);

export default InfoPage;
