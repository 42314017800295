import React from "react";
import PageLayout from "./PageLayout";
import InfoPage from "./InfoPage";

const ErrorPage: React.FC = () => {
  const erroMessage =
    "The sales submission link is invalid. Please go back to your notification and re-click your link to try again. \r\n\r\nPlease contact your Centre Management team for further assistance.";

  return (
    <PageLayout
      centreName="Charter Hall"
      centrePhoneNumber="+61 2 8651 9900"
      centreEmail="servicedesk@charterhall.com.au"
    >
      <InfoPage
        centreName="Charter Hall"
        centrePhoneNumber="+61 2 8651 9900"
        centreEmail="servicedesk@charterhall.com.au"
        greeting="Invalid Link"
        message={erroMessage}
      />
    </PageLayout>
  );
};

export default ErrorPage;
