import React from "react";

interface Props {
  centreName: string;
  centrePhoneNumber: string | null;
  centreEmail: string | null;
}

const ContactInformation: React.FC<Props> = (props) => (
  <React.Fragment>
    <h3>{props.centreName}</h3>
    {(props.centrePhoneNumber || props.centreEmail) && (
      <p>
        {props.centrePhoneNumber && (
          <React.Fragment>
            <a href={"tel:" + props.centrePhoneNumber}>
              {props.centrePhoneNumber}
            </a>
            <br />
          </React.Fragment>
        )}
        {props.centreEmail && (
          <a href={"mailto:" + props.centreEmail}>{props.centreEmail}</a>
        )}
      </p>
    )}
  </React.Fragment>
);

export default ContactInformation;
