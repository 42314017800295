import React, { useState } from "react";

import InfoPage from "./InfoPage";
import SalesSubmissionForm from "./SalesSubmissionForm";
import SalesSubmissionModal from "./SalesSubmissionModal";
import SalesSubmissionErrorModal from "./SalesSubmissionErrorModal";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

interface Props {
  tradeName: string;
  centreName: string;
  centrePhoneNumber: string | null;
  centreEmail: string | null;
  submissionMonthName: string;
  submissionYear: number;
}

const SalesSubmissionPage: React.FC<Props> = (props) => {
  const [submissionValue, setSubmissionValue] = useState("");
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setShowSubmitModal(true);
  };

  const handleSuccessfulSubmission = () => {
    setShowSubmitModal(false);
    setSubmitted(true);
  };

  const handleFailedSubmission = () => {
    setShowSubmitModal(false);
    setShowError(true);
  };

  return (
    <React.Fragment>
      {submitted ? (
        <InfoPage
          centreName={props.centreName}
          centrePhoneNumber={props.centrePhoneNumber}
          centreEmail={props.centreEmail}
          greeting={"Sales successfully submitted!"}
          message={`Thank you! Your ${props.submissionMonthName} ${props.submissionYear} Sales figure has been successfully submitted. You can now close the window. \r\n\r\nIf you have any questions, please get in touch with your Centre Management team.`}
        />
      ) : (
        <React.Fragment>
          <Row>
            <Col>
              <h2>Hello, {props.tradeName}</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                Sales Reporting Month: {props.submissionMonthName}{" "}
                {props.submissionYear}
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                It’s time to submit your monthly sales figure to Centre
                Management. Please use the field below to submit your sales for{" "}
                {props.submissionMonthName} {props.submissionYear} excluding
                GST.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <SalesSubmissionForm
                submissionValue={submissionValue}
                setSubmissionValue={setSubmissionValue}
                handleSubmit={handleSubmit}
              />
            </Col>
          </Row>
        </React.Fragment>
      )}
      <SalesSubmissionModal
        submissionValue={submissionValue}
        showSubmitModal={showSubmitModal}
        setShowSubmitModal={setShowSubmitModal}
        handleSuccessfulSubmission={handleSuccessfulSubmission}
        handleFailedSubmission={handleFailedSubmission}
      />
      <SalesSubmissionErrorModal
        showError={showError}
        setShowError={setShowError}
        centreName={props.centreName}
        centrePhoneNumber={props.centrePhoneNumber}
        centreEmail={props.centreEmail}
      />
    </React.Fragment>
  );
};

export default SalesSubmissionPage;
