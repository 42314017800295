import React, { useState } from "react";

import { useParams } from "react-router-dom";

import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";

interface Props {
  submissionValue: string;
  showSubmitModal: boolean;
  setShowSubmitModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleSuccessfulSubmission: () => void;
  handleFailedSubmission: () => void;
}

interface Params {
  token: string;
}

const SalesSubmissionModal: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState(false);

  const { token }: Params = useParams();

  const handleSubmitConfirmation = async (
    _event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    try {
      setLoading(true);
      const sales = props.submissionValue.replace(/[^0-9.]/g, "");
      const data = { sales };

      const response = await fetch(
        `${process.env.REACT_APP_AZURE_FUNCTION_APP_URL}/api/SubmitSales?token=${token}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) throw Error(response.statusText);

      setLoading(false);
      props.handleSuccessfulSubmission();
    } catch {
      setLoading(false);
      props.handleFailedSubmission();
    }
  };

  return (
    <Modal
      show={props.showSubmitModal}
      backdrop="static"
      centered
      restoreFocus={false}
    >
      <Modal.Header className="border-bottom-0 p-4">
        <Col xs="auto" className="p-0">
          <h2>Confirmation</h2>
        </Col>
        <Col className="my-auto d-flex justify-content-end">
          {loading && <Spinner animation="border" size="sm" variant="dark" />}
        </Col>
      </Modal.Header>
      <Modal.Body className="pt-0 pl-4 pb-0 pr-4">
        <p className="mb-0">
          You are submitting the sales figure of {props.submissionValue} (ex
          GST) is this correct?
        </p>
      </Modal.Body>
      <Modal.Footer className="border-top-0 pl-4 pr-4 pb-4">
        <Button
          variant="link"
          disabled={loading}
          onClick={() => props.setShowSubmitModal(false)}
        >
          Go back
        </Button>
        <Button
          variant="primary"
          disabled={loading}
          onClick={handleSubmitConfirmation}
        >
          Submit
        </Button>
      </Modal.Footer>
      <Modal.Footer className="border-top-0 text-body bg-secondary">
        <p>
          <small>
            All personal information submitted will be treated in accordance
            with our{" "}
            <a
              href="https://www.charterhall.com.au/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              className="text-info"
            >
              privacy policy
            </a>
            .
          </small>
        </p>
      </Modal.Footer>
    </Modal>
  );
};

export default SalesSubmissionModal;
