import React from "react";

import ContactInformation from "./ContactInformation";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

interface Props {
  showError: boolean;
  setShowError: React.Dispatch<React.SetStateAction<boolean>>;
  centreName: string;
  centrePhoneNumber: string | null;
  centreEmail: string | null;
}

const SalesSubmissionErrorModal: React.FC<Props> = (props) => {
  return (
    <Modal
      show={props.showError}
      onHide={() => props.setShowError(false)}
      centered
      restoreFocus={false}
    >
      <Modal.Header className="border-bottom-0 p-4">
        <h2 className="text-danger">Submission error</h2>
      </Modal.Header>
      <Modal.Body className="pt-0 pl-4 pb-0 pr-4">
        <p>
          There was an error with this submission. Your monthly sales figure has
          not been received.
        </p>
        <p>
          Please refresh your link to try again or get in touch with your Centre
          Management team.
        </p>
        <ContactInformation
          centreName={props.centreName}
          centrePhoneNumber={props.centrePhoneNumber}
          centreEmail={props.centreEmail}
        />
      </Modal.Body>
      <Modal.Footer className="border-top-0 pl-4 pr-4 pb-4">
        <Button variant="link" onClick={() => props.setShowError(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SalesSubmissionErrorModal;
