import React, { useState, useEffect } from "react";
import styles from "./PageLayout.module.scss";

import HelpButton from "./HelpButton";

import logo from "../img/logo.svg";
import defaultCentreImage from "../img/default.jpg";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

interface Props {
  centreName: string;
  centrePhoneNumber: string | null;
  centreEmail: string | null;
  children: React.ReactNode;
  propertyCode?: string;
}

const PageLayout: React.FC<Props> = (props) => {
  const [centreImageUrl, setCentreImageUrl] = useState(defaultCentreImage);

  useEffect(() => {
    if (props.propertyCode === undefined)
      return setCentreImageUrl(defaultCentreImage);

    (async () => {
      try {
        const imgUrl =
          process.env.PUBLIC_URL + `/img/${props.propertyCode}.jpg`;

        const response = await fetch(imgUrl, {
          method: "HEAD",
        });

        if (!response.ok) return setCentreImageUrl(defaultCentreImage);

        setCentreImageUrl(imgUrl);
      } catch {
        setCentreImageUrl(defaultCentreImage);
      }
    })();
  }, [props.propertyCode]);

  return (
    <React.Fragment>
      <Container fluid className="p-3">
        <Row>
          <Col xs={7} className="my-auto">
            <Image src={logo} fluid className={styles.logo} />
          </Col>
          <Col xs={5} className="my-auto d-flex justify-content-end">
            <HelpButton
              centreName={props.centreName}
              centrePhoneNumber={props.centrePhoneNumber}
              centreEmail={props.centreEmail}
            />
          </Col>
        </Row>
      </Container>
      <Container fluid className="p-0">
        <Row className="no-gutters">
          <Col>
            <div
              style={{ backgroundImage: `url(${centreImageUrl})` }}
              className={styles.imageContainer}
            ></div>
          </Col>
        </Row>
      </Container>
      <Container className={styles.mainContent}>
        <Row>
          <Col lg={{ span: 8, offset: 2 }}>
            <div className={`${styles.centreTitleContainer} p-3 mb-2`}>
              <h1 className="m-0">{props.centreName}</h1>
            </div>
            <div className={`${styles.contentContainer} p-4`}>
              {props.children}
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default PageLayout;
