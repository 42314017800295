import React from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import TenantPage from "./components/TenantPage";
import ErrorPage from "./components/ErrorPage";

const App: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route path="/:token">
          <TenantPage />
        </Route>
        <Route path="/">
          <ErrorPage />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
