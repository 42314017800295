/**
 * @returns the name of the month for the given number.
 */
export const getMonthName = (monthNumber: number) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return monthNames[monthNumber - 1];
};

/**
 * @returns the user message to be displayed if the sales window is closed, or the sales status is submitted. This function assumes that the link is valid the sales have already been submitted.
 */
export const getMessage = (
  activeLink: boolean,
  submissionYear: number,
  submissionMonthName: string
) => {
  // sales window closed
  if (!activeLink)
    return (
      "The sales reporting period for " +
      submissionMonthName +
      " " +
      submissionYear +
      " has now closed. Please contact Centre Management for further assistance."
    );

  // sales status submitted
  return (
    "Your monthly sales for " +
    submissionMonthName +
    " " +
    submissionYear +
    " have already been submitted. Please contact Centre Management if you require further assistance."
  );
};
