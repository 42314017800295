import React, { useState } from "react";

import ContactInformation from "./ContactInformation";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

interface Props {
  centreName: string;
  centrePhoneNumber: string | null;
  centreEmail: string | null;
}

const HelpButton: React.FC<Props> = (props) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <React.Fragment>
      <Button variant="link" onClick={() => setShowModal(true)}>
        Help
      </Button>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        restoreFocus={false}
      >
        <Modal.Header className="border-bottom-0 p-4">
          <h2>Help</h2>
        </Modal.Header>
        <Modal.Body className="pt-0 pl-4 pb-0 pr-4">
          <p>
            This application has been designed by Charter Hall to collect your
            monthly sales figure. To reference our privacy policy please see{" "}
            <a
              href="https://www.charterhall.com.au/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              className="text-info"
            >
              here
            </a>
            . Alternatively if you would like some help or change the way you
            receive this notification please contact our Centre Management team.
          </p>
          <ContactInformation
            centreName={props.centreName}
            centrePhoneNumber={props.centrePhoneNumber}
            centreEmail={props.centreEmail}
          />
        </Modal.Body>
        <Modal.Footer className="border-top-0 pl-4 pr-4 pb-4">
          <Button variant="link" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default HelpButton;
