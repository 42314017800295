import React, { useEffect, useState } from "react";
import styles from "./TenantPage.module.scss";

import { useParams } from "react-router-dom";

import PageLayout from "./PageLayout";
import InfoPage from "./InfoPage";
import ErrorPage from "./ErrorPage";
import SalesSubmissionPage from "./SalesSubmissionPage";

import { TokenDetails } from "../models/tokenDetails";
import { getMonthName, getMessage } from "../utils";

import Spinner from "react-bootstrap/Spinner";

interface Params {
  token: string;
}

const TenantPage: React.FC = () => {
  const [showError, setShowError] = useState(false);
  const [tokenDetails, setTokenDetails] = useState<TokenDetails | null>(null);

  const { token }: Params = useParams();

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_AZURE_FUNCTION_APP_URL}/api/GetTenantDetails?token=${token}`
        );
        if (!response.ok) throw Error(response.statusText);
        setTokenDetails(await response.json());
      } catch {
        setShowError(true);
      }
    })();
  }, [token]);

  if (showError) return <ErrorPage />;

  if (!tokenDetails)
    return (
      <div className={styles.spinner}>
        <Spinner animation="border" />
      </div>
    );

  const submissionMonthName = getMonthName(tokenDetails.submissionMonth);

  if (tokenDetails.isValid && !tokenDetails.submitted)
    return (
      <PageLayout
        centreName={tokenDetails.propertyName}
        centrePhoneNumber={tokenDetails.propertyPhoneNumber}
        centreEmail={tokenDetails.propertyEmail}
        propertyCode={tokenDetails.propertyCode}
      >
        <SalesSubmissionPage
          tradeName={tokenDetails.tradeName}
          centreName={tokenDetails.propertyName}
          centrePhoneNumber={tokenDetails.propertyPhoneNumber}
          centreEmail={tokenDetails.propertyEmail}
          submissionMonthName={submissionMonthName}
          submissionYear={tokenDetails.submissionYear}
        />
      </PageLayout>
    );

  const message = getMessage(
    tokenDetails.isValid,
    tokenDetails.submissionYear,
    submissionMonthName
  );

  return (
    <PageLayout
      centreName={tokenDetails.propertyName}
      centrePhoneNumber={tokenDetails.propertyPhoneNumber}
      centreEmail={tokenDetails.propertyEmail}
      propertyCode={tokenDetails.propertyCode}
    >
      <InfoPage
        centreName={tokenDetails.propertyName}
        centrePhoneNumber={tokenDetails.propertyPhoneNumber}
        centreEmail={tokenDetails.propertyEmail}
        greeting={`Hello, ${tokenDetails.tradeName}`}
        message={message}
      />
    </PageLayout>
  );
};

export default TenantPage;
